import { Component } from '@angular/core';

@Component({
  selector: 'app-mobile-footer-navigation',
  template: `
    <div class="mobile-footer-navigation">
      <a routerLink="/" class="nav-button home-button">
        <mat-icon class="footer-material-icon">home</mat-icon>
      </a>
      <a
        routerLink="/discover"
        class="nav-button discover-button disabled-icon"
      >
        <mat-icon class="footer-material-icon">search</mat-icon>
      </a>
      <a routerLink="/mypage/upload" class="nav-button add-button">
        <mat-icon class="footer-material-icon">add_circle_outline</mat-icon>
      </a>
      <a routerLink="/" class="nav-button inbox-button">
        <mat-icon class="footer-material-icon disabled-icon"
          >notifications</mat-icon
        >
      </a>
      <a routerLink="/mypage" class="nav-button profile-button">
        <mat-icon class="footer-material-icon">person_outline</mat-icon>
      </a>
    </div>
  `,
  styles: [
    `
      @import 'src/styles/scss/variables';
      .mobile-footer-navigation {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        height: $mobile-footer-height;
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 0.5rem 0;
        background-color: #2d3748;
      }

      .footer-material-icon {
        font-size: $mobile-footer-icon-size; // 24px * 1.5
        width: $mobile-footer-icon-size;
        height: $mobile-footer-icon-size;
      }

      a {
        color: white;
      }
      svg {
        stroke: white;
      }
      .disabled-icon {
        color: #808080; /* または rgba(255, 255, 255, 0.5) でより柔らかいグレー */
        opacity: 0.5;
      }

      .nav-button {
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
      }
    `,
  ],
})
export class MobileFooterNavigationComponent {}
