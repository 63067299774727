import { Component, OnInit } from '@angular/core';
import { AuthService, UserInfo } from 'src/app/core/auth/auth.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs'; // finalize を追加

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  service_name: string = `${environment.service_name}`;
  loggedInUserInfo$: Observable<UserInfo | null> =
    this.authService.loggedInUserInfo$;

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    // サービス側のログイン状態を取得するメソッドを実行(サービス内の会員情報loggedInUserInfo$も更新される)
    this.authService.checkAuthenticationStatus().subscribe();
  }

  onLogout() {
    // logout() の Observable を購読することで、
    // AuthService 内で CSRF トークン更新とページ遷移が実施されます。
    this.authService.logout().subscribe({
      next: (response) => {
        console.log('onLogout : ログアウトに成功しました。', response);
        // 必要に応じて、ログアウト後に認証状態の再確認を実施
        this.authService.checkAuthenticationStatus().subscribe({
          next: () => console.log('認証状態が確認されました。'),
          error: (error) => console.error('認証状態確認中のエラー:', error),
        });
      },
      error: (error) => {
        console.error('logout() : ログアウトエラー:', error);
        alert('ログアウトに失敗しました。再試行してください。');
      },
    });
  }
}
