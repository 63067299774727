import { NgModule } from '@angular/core';
import {
  RouterModule,
  Routes,
  Router,
  NavigationEnd,
  NavigationError,
  NavigationCancel,
  NavigationStart,
} from '@angular/router';

const routes: Routes = [
  // {
  //   path: 'headquarters',
  //   redirectTo: '/headquarters/index.php',
  //   pathMatch: 'full',
  // },

  {
    path: '',
    loadChildren: () =>
      import('./features/features.module').then((m) => m.FeaturesModule),
  },

  {
    path: 'auth',
    loadChildren: () =>
      import('./core/auth/auth.module').then((m) => m.AuthModule),
  },

  // ワイルドカードルート - 存在しないURLへのアクセスを処理
  {
    path: '**',
    redirectTo: '', // ルートにリダイレクト
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(private router: Router) {
    // すべてのナビゲーションイベントをリッスン
    this.router.events.subscribe((event) => {
      // ナビゲーション開始時
      if (event instanceof NavigationStart) {
        console.log('ナビゲーション開始:', event.url);
      }

      // ナビゲーション成功時
      if (event instanceof NavigationEnd) {
        console.log('ナビゲーション成功:', event.url);
        if (event.url.includes('headquarters')) {
          console.log('headquarters関連のナビゲーション完了:', event);
        }
      }

      // ナビゲーションキャンセル時
      if (event instanceof NavigationCancel) {
        console.log('ナビゲーションキャンセル:', event.url);
        console.log('キャンセル理由:', event.reason);
      }

      // ナビゲーションエラー時
      if (event instanceof NavigationError) {
        console.error('ナビゲーションエラー:', event.url);
        console.error('エラー:', event.error);
      }
    });
  }
}
